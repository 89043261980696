import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import logo from './polemoslogo-black-large.png';


import './App.css';

function App() {
  return (
    <div>
      <div className="polemos_logo_container">
        <img className="polemos_logo" src={logo} alt="Polemos Logo" />
      </div>
      <SwaggerUI url={"./polemos-dataanalytics-1.0.0-resolved.json"} />
    </div>
  );
}

export default App;
